import React, { useState } from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2, H3 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import AppLinkBar from 'primitives/appLinkBar';
import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import HeroCover from 'components/hero/heroCover';
import Highlights from 'components/highlights';
import GalleryGrid from 'components/gallery/galleryGrid';
import BannerGradient from 'components/banner/bannerGradient';
import { AppButton } from 'primitives/appButton';

import { ShText } from '@shoootin/translations';

import { FrontCommonMessages } from '../../../appCommonTranslations';

// @ts-ignore
import { device } from 'helpers/helpers';

import { StickyContainer, Sticky } from 'react-sticky';

// @ts-ignore
import { enhanceHeader } from 'helpers/helpers';
import '../frontPrestation.less';
import PrestationOthers from '../prestationOthers';
import { getStickyStyle } from '../prestationCommons';

import {
  heroCover,
  PrestationFigures,
  prestationHighlights,
  PrestationInNav,
  PrestationProsTabSet,
} from './dronePageValues';

import PrestationTabsetPros from '../prestationPagePros';
import PrestationPageFigures from '../prestationPageFigures';
import { FrontDroneMessages } from './dronePageTranslations';

import { CoverPhotoGatsbyImage } from '../../../components/coverPhoto';
import CoverVideo from '../../../components/coverVideo';
import VideoModal from '../../../components/video/videoModal';
import { graphql } from 'gatsby';
import { ServiceDronePageQuery } from '../../../appGraphQLTypes';
import { getFluidImages } from '../../../appGatsbyImageUtils';
import usePrestationMainImages from '../../../queries/usePrestationMainImages';
import Usecase from '../../../components/usecase';
import { ShCol, ShRow } from '@shoootin/components-web';

// don't forget to set this to correct prestation Messages.
const SpecificPageMessages = FrontDroneMessages;

const DronePage = ({ data }: { data: ServiceDronePageQuery }) => {
  const [videoId, setVideoId] = useState<string>();

  const heroImage = usePrestationMainImages().drone;

  return (
    <MainLayout className="page-prestation" pageName="prestation_drone">
      <HeroCover image={heroImage} values={heroCover} />

      <StickyContainer>
        <div className="section prestation-intro" id="intro">
          <div className="container">
            <Sticky>
              {({ style, isSticky }) => {
                const stickyStyle = getStickyStyle(isSticky);
                return (
                  <nav
                    id="linknav"
                    className={isSticky ? 'fixed' : ''}
                    style={{
                      ...style,
                      ...stickyStyle,
                    }}
                  >
                    <AppLinkBar
                      links={PrestationInNav}
                      scroll={true}
                      modifier="swiper-container-horizontal swiper-container-free-mode"
                    />
                  </nav>
                );
              }}
            </Sticky>

            {/* <ShRow>
              <ShCol sm={{ span: 40, offset: 10 }} xs={60}>
                <CoverPhotoGatsbyImage
                  fluid={data.coverIntroImage!.childImageSharp!.fluid!}
                />
              </ShCol>
            </ShRow> */}
          </div>
          <Highlights highlights={prestationHighlights} />
          {/* <Cover */}
          {/* path={require('images/background/banner-matterport.jpg')} */}
          {/* target="#" */}
          {/* mode="matterport" */}
          {/* /> */}
          <CoverVideo
            path={data.coverHeroVideoImage!.childImageSharp!.fluid!}
            modifier="banner"
            onClick={() =>
              setVideoId('https://www.youtube.com/watch?v=pp32N-mdsQQ')
            }
          />
          <VideoModal videoId={videoId} onClose={() => setVideoId(undefined)} />
          <PrestationPageFigures
            figures={PrestationFigures}
            images={getFluidImages(data, 'figureImages')}
            coverCaption={SpecificPageMessages.coverCaption}
          />
        </div>

        <AppSection
          id="pros"
          header={
            <H2>
              <ShText message={SpecificPageMessages.advantagesTitle} />
            </H2>
          }
        >
          <PrestationTabsetPros
            nav={PrestationProsTabSet}
            quarterMode="drone"
            images={getFluidImages(data, 'benefitsImages')}
          />
        </AppSection>

        <AppSection
          className="grid-gallery"
          id="gallery"
          header={
            <H2>
              <ShText message={FrontCommonMessages.gallery} />
            </H2>
          }
        >
          <GalleryGrid />
        </AppSection>

        <AppSection
          className="section-usecase"
          id="usecase"
          header={
            <H2>
              <ShText message={FrontCommonMessages.usecase} />
            </H2>
          }
        >
          <Usecase name="convini" />
        </AppSection>

        {/* <AppSection
          className="prestation-price"
          id="price"
          header={
            <H2>
              <ShText message={SpecificPageMessages.priceTitle1} />
              <br />
              <ShText message={SpecificPageMessages.priceTitle2} />
            </H2>
          }
        >
          <div key="cover-prices">

            <CoverMetiers
              professions={[
                'residentialRealEstate',
                'commercialRealEstate',
                'architect',
                'hotel',
                'restaurant',
              ]}
            />
          </div>
        </AppSection> */}
      </StickyContainer>
      <BannerGradient className="banner--gradient-drone">
        <H3>
          <ShText message={SpecificPageMessages.bannerTitle} />
        </H3>
        <p>
          <ShText message={SpecificPageMessages.bannerP} />
        </p>
        <AppInternalLink to={AppInternalPaths.order}>
          <AppButton modifier={['light', 'large']}>
            <ShText message={SpecificPageMessages.bannerCta} />
          </AppButton>
        </AppInternalLink>
      </BannerGradient>

      <PrestationOthers prestationName="drone" />
    </MainLayout>
  );
};

export default DronePage;

export const query = graphql`
  query ServiceDronePage {
    #coverIntroImage: file(relativePath: { eq: "prestation_drone_intro.jpg" }) {
    #  ...PrestationCoverIntroImageFragment
    #}
    coverHeroVideoImage: file(
      relativePath: { eq: "prestation_drone_videocover.jpg" }
    ) {
      ...AppFluidHeroImageFragment
    }
    figureImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_drone_service-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationFigureImageFragment
        }
      }
    }
    benefitsImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_drone_avantages-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationBenefitsImageFragment
        }
      }
    }
  }
`;
