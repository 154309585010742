import React from 'react';
import classnames from 'classnames';
import CloseSvg from '../svg/picto/closeSvg';
import ReactPlayer from 'react-player';
import { ShColors } from '@shoootin/design-tokens';
import { ShModal, ShModalStyle, ShModalSize } from '@shoootin/components-web';

// use this version when zIndex is not an issue
export const VideoModal2 = ({
  videoId,
  title,
  onClose,
  style = 'transparent',
  size = 'l',
}: {
  videoId?: string;
  title?: string | React.ReactNode;
  onClose: () => void;
  style?: ShModalStyle;
  size?: ShModalSize;
}) => {
  if (!videoId) return null;
  // fix modal zIndex
  return (
    <ShModal
      style={style}
      title={title}
      size={size}
      onClose={onClose}
      content={
        <div
          css={{
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%',
            background: '#000',
            marginBottom: 15,
            marginTop: 15,
          }}
        >
          <ReactPlayer
            url={videoId}
            playing={true}
            width="100%"
            height="100%"
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 1,
                  modestbranding: 1,
                  rel: 0,
                  color: ShColors.base,
                },
              },
            }}
          />
        </div>
      }
    />
  );
};

// TODO avoid using this if zIndex issue is not present
const VideoModal = ({
  videoId,
  onClose,
}: {
  videoId?: string;
  onClose: () => void;
}) => {
  return (
    <div className={classnames('video-modal', { active: !!videoId })}>
      {videoId && (
        <>
          <a className="video-modal-close" onClick={onClose}>
            <CloseSvg />
          </a>
          <ReactPlayer
            url={videoId}
            playing={true}
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 0,
                  modestbranding: 1,
                  rel: 0,
                  color: ShColors.base,
                },
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default VideoModal;
